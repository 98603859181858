
import { defineComponent, computed } from "vue";
import DetailsSection from "@/components/details/DetailsSection.vue";
import WorkAreaTable from "@/features/workAreas/table/WorkAreaTable.vue";
// store
import useWorkAreaStore from "@/store/useWorkAreaStore";
import useEmployeeWorkAreaStore from "@/store/useEmployeeWorkAreaStore";
// other
import { useRoute } from "vue-router";
import { repairUuid } from "@/utils/globalHelpers";

export default defineComponent({
  name: "WorkAreaDetailsPageEmployees",
  components: {
    DetailsSection,
    WorkAreaTable
  },
  setup() {
    //store
    const { workAreas } = useWorkAreaStore({ allWorkAreas: true });
    const {
      employeeWorkAreas,
      onCreateEmployeeWorkArea,
      onCreateEmployeeWorkAreaSuccess,
      onDeleteEmployeeWorkArea,
      onDeleteEmployeeWorkAreaSuccess
    } = useEmployeeWorkAreaStore({
      allEmployeeWorkAreas: true
    });

    // get employee id
    const {
      params: { employeeId: employeeUrlId }
    } = useRoute();
    const employeeId = computed(() => repairUuid(employeeUrlId));

    // get assignments of considered employee
    const employeeWorkAreasFiltered = computed(() =>
      employeeWorkAreas.value.filter(item => item.employee_id === employeeId.value)
    );
    // get work area ids
    const workAreaIds = computed(() => employeeWorkAreasFiltered.value.map(item => item.work_area_id));

    onCreateEmployeeWorkAreaSuccess(() => {
      console.info("Employee assigned to work area");
    });

    onDeleteEmployeeWorkAreaSuccess(() => console.info("Employee removed from work area"));

    // remove or add employee to work area
    const onSelectWorkArea = (workAreaId: string) => {
      // remove
      if (workAreaIds.value?.includes(workAreaId)) {
        onDeleteEmployeeWorkArea(workAreaId, employeeId.value);
        // add
      } else {
        onCreateEmployeeWorkArea(workAreaId, employeeId.value);
      }
    };

    return {
      workAreas,
      workAreaIds,
      onSelectWorkArea
    };
  }
});
